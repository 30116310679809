import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import '../styles/Publikationen.css';

import { useEffect, useRef, useState } from 'react';


function Publikationen()
{
    window.scrollTo(0,0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [PublikationenBody, setPublikationenBody] = useState('PublikationenBody');
    const [PubScreenTitel1, setPubScreenTitel1] = useState('PubScreenTitel1');
    const [PubScreenTitel2, setPubScreenTitel2] = useState('PubScreenTitel2');
    const [PubRowBullet, setPubRowBullet] = useState('PubRowBullet');
    const [PubRowTitel, setPubRowTitel] = useState('PubRowTitel');
    const [RG_Link, setRG_Link] = useState('RG_Link');
    const [KuJM_Link, setKuJM_Link] = useState('KuJM_Link');
    const [SLLD_Link, setSLLD_Link] = useState('SLLD_Link');
    const [Springer_Link, setSpringer_Link]= useState('Springer_Link');
    const [MedienPaed_Link, setMedienPaed_Link] = useState('MedienPaed_Link');
    const [sciendo_Link, setSciendo_Link] = useState('sciendo_Link');

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
            setPublikationenBody('PublikationenBody_Mobile');
            setPubScreenTitel1('PubScreenTitel1_Mobile');
            setPubScreenTitel2('PubScreenTitel2_Mobile');
            setPubRowBullet('PubRowBullet_Mobile');
            setPubRowTitel('PubRowTitel_Mobile');
            setRG_Link('RG_Link_Mobile');
            setSLLD_Link('SLLD_Link_Mobile');
            setSpringer_Link('Springer_Link_Mobile');
            setMedienPaed_Link('MedienPaed_Link_Mobile');
            setKuJM_Link('KuJM_Link_Mobile');
            setSciendo_Link('sciendo_Link_Mobile');
        }

    }, windowSize.current[0])


    return(
    <div className='PublikationenWrapper'>
        <Navigation></Navigation>
        <div className='SiteBackground'></div>
        <div className={PublikationenBody}>
            <div className='PubScreen'>
                <div className={PubScreenTitel1}>Publikationen</div>
                <div className={PubScreenTitel2}>Beiträge in Sammelbänden</div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Gailberger, Steffen &  Helm, Gerrit (2024): kluug das Lesen fördern. Digitale systematische Leseförderung für die Grundschule. In: Carl, Mark-Oliver & Jörgens, Moritz & Schulze, Tina (Hrsg.): <i>Literarische Texte lesen – Texte literarisch lesen. Festschrift für Cornelia Rosebrock</i>. J.B. Metzler, S. 143-172.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://link.springer.com/chapter/10.1007/978-3-662-67816-9_9"><div className={Springer_Link}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Dube, Juliane &  Helm, Gerrit & Ronge, Verena (2023): Kindliches Spiel und ästhetische Erfahrungen am Beispiel von Bilderbuch-Apps. Erste Ergebnisse einer rekonstruktiven Fallstudie. In: Dichtl, Eva-Marie & Bernhardt, Sebastian (Hrsg.): <i>Frühkindliches Spiel und literarische Rezeption</i>. Frank & Timme, S. 153-188.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://www.researchgate.net/publication/373158588_Kindliches_Spiel_und_asthetische_Erfahrungen_mit_polymodalen_Texten_am_Beispiel_von_Bilderbuch-Apps"><div className={RG_Link}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Gailberger, Steffen & Helm, Gerrit & Nix, Daniel (2022): Lesen und Leseförderung in der Primar- und Sekundarstufe. In: Gailberger, Steffen & Wietzke, Frauke (Hrsg.): <i>Handbuch Kompetenzorientierter Deutschunterricht</i>. 2. Aufl. S. 38-93. </div>
                    <div className='PubRowLinkArea'>
                        
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2021): "Das Ausmaß der Unterschiede in den Lese-Items des Salzburger Lese-Screening 2-9" In: Gailberger, Steffen & Sappok, Christopher (Hrsg.): <i>Weiterführende Grundlagenforschung in Lesedidaktik und Leseförderung: Theorie, Empirie, Anwendung.</i> SLLD-B.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://omp.ub.rub.de/index.php/SLLD/catalog/download/189/167/1102?inline=1"><div className={SLLD_Link}></div></a>
                    </div>
                </div>
                <div className={PubScreenTitel2}>Zeitschriftenbeiträge</div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit & Hesse, Florian (2024): Usage and beliefs of student teachers towards artificial intelligence in writing. In: <i>Research in Subject-matter Teaching and Learning (RISTAL)</i>. 7 (1). S. 1-18.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://sciendo.com/de/article/10.2478/ristal-2024-0001"><div className={sciendo_Link}></div></a>
                    </div>
                </div>
                
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit & Dube, Juliane (2024): Hingeschaut – Bilderbuch-Apps zum literarischen Lernen im inklusiven Deutschunterricht. In: <i>MedienPädagogik. Zeitschrift für Theorie und Praxis der Medienbildung</i>.</div>
                    <div className='PubRowLinkArea'>
                        <a href="https://www.medienpaed.com/article/view/1616"><div className={MedienPaed_Link}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2022): "Das lokomotische Satzmodell: Satztopologie im Unterricht der Primarstufe" SLLD-U. </div>
                    <div className='PubRowLinkArea'>
                        <a href="https://doi.org/10.46586/SLLD.220"><div className={SLLD_Link}></div></a>
                    </div>
                </div>

                <div className={PubScreenTitel2}>Poster</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2024): "AiDe-L: (Browser-)Anwendung zur individuellen Diagnose im Lesen." Poster zum 8. Arbeitstreffen der AG Leseverstehen. </div>
                    <div className='PubRowLinkArea'>
                    <a href="https://www.researchgate.net/publication/378434648_AiDe-L_Browser-Anwendung_zur_individuellen_Diagnose_im_Lesen_Poster_zum_8_Arbeitstreffen_der_AG_Leseverstehen_in_Koblenz"><div className={RG_Link}></div></a>
                    </div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2018): "Ein Modell des hierarchischen Lesens und seine lesedidaktischen Implikationen." Poster zum 22. Symposion Deutschdidaktik. </div>
                    <div className='PubRowLinkArea'>
                    </div>
                </div>

                <div className={PubScreenTitel2}>Rezensionen</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Hesse Florian & Helm, Gerrit (2024): "Rundisc: Chants of Sennaar" [Rezension]: <i>KinderundJugendmedien.de</i>.</div>
                    <div className='PubRowLinkArea'>
                    <a href="https://www.kinderundjugendmedien.de/kritik/computerspiele/7152-rundisc-chants-of-sennar"><div className={KuJM_Link}></div></a>
                    </div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Hesse Florian & Helm, Gerrit (2024): "Black Salt Games: Dregde" [Rezension]: <i>KinderundJugendmedien.de</i>.</div>
                    <div className='PubRowLinkArea'>
                    <a href="https://www.kinderundjugendmedien.de/kritik/computerspiele/7223-black-salt-games-dregde"><div className={KuJM_Link}></div></a>
                    </div>
                </div>

                <div className={PubScreenTitel2}>Vorträge (ausgewählte)</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>5.11.2024</b> Schreiben mit KI an der Hochschule: Status Quo und Perspektiven nach zwei Jahren ChatGPT (Jahrestagung 2024 des eTeach-Netzwerks Thüringen)</div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>18.09.2024</b> KI als Ressource zum Verfassen professioneller Schreibprodukte im Lehramtsstudium (25. Symposion Deutschdidaktik)</div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>1.07.2024</b> Self-regulated exploration in educational videogames (Di.ge.LL-Conference Freiburg)</div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>21.06.2024:</b> Sprachsystematische Lesediagnose und der Transfer in die Leseförderung (1. SDD Postdoc-Akademie, FSU Jena)</div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>16.03.2023:</b> Ästhetische Erfahrungen im Umgang mit Bilderbuch-Apps (zusammen mit Juliane Dube) (Jahrestagung der AG Medien des Symposion Deutschdidaktik e. V. an der Pädagogischen Hochschule Freiburg)</div>
                </div>
                
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>18.01.2020</b> Das Erfassen von orthographischen Fertigkeiten von Schülerinnen und Schülern mit Hilfe einer Android-App (FORTE Tagung, Universität Bremen)</div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>17.01.2020</b> Lesekompetenz und Lesediagnose (Gastvortrag an der Leuphana Universität Lüneburg)</div>
                </div>

                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>8.12.2018</b> Lesekompetenz und Lesediagnose aus linguistischer Perspektive: Die Graphem-Ebene (11. Nordwestdeutsches Linguistisches Kolloquium, C.v.O Universität Oldenburg)</div>
                </div>

                <div className={PubScreenTitel2}>Workshops und Fortbildungen (ausgewählte)</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>7.11.2024</b> Feedback mit KI im Lehramt (Workshop im Rahmen des 1. Jenaer Fachtags Deutsch)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>
                <div className='PubRow'>
                    <div className={PubRowBullet}></div>
                    <div className={PubRowTitel}><b>17.10.2024</b> Leseförderung im Kontext von Schulentfremdung (Fortbildung für Fachberater:innen im Auftrag des ThILLM)</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>

                <div className={PubScreenTitel2}>Sonstiges</div>
                <div className='PubRow'>
                    <div className={PubRowBullet}>.</div>
                    <div className={PubRowTitel}>Helm, Gerrit (2022): "Van den vos Reynaerde - Didaktische Aspekte" [Vorwort zu]: Schlusemann, Rita (Hrsg.): <i>Van den vos Reynaerde. Relectiones</i>. Hirzel Verlag.</div>
                    <div className='PubRowLinkArea'>   
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
        )
}

export default Publikationen