import { useState } from 'react';
import Poster from '../material/Poster_SDD_AiDe-L_HELM.pdf'
import '../styles/PDFViewer.css';

function Poster_SDD_2024_Viewer()
{

    const [PosterViewerWrapper, setPosterViewerWrapper] = useState('PosterViewerWrapper')

    return(
        <div className={PosterViewerWrapper}>
            <iframe
                src={Poster} 
                width="100%" 
                height="auto" 
                title="PDF Viewer" >

                </iframe>
        </div>
    )
}
export default Poster_SDD_2024_Viewer;