import "../styles/Impressum.css";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { useEffect, useRef, useState } from 'react';

function Impressum()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ImpressumBody, setImpressumBody] = useState('ImpressumBody');
    const [ImpressumField, setImpressumField] = useState('ImpressumField');
    const [Impressum_TextRow, setImpressum_TextRow] = useState('Impressum_TextRow_Mobile');
    const [Impressum_TextRow_Blank, setImpressum_TextRow_Blank] = useState('Impressum_TextRow_Blank');
    const [ImpressumField_Titel, setImpressumField_Titel] = useState('ImpressumField_Titel');

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
            setImpressumBody('ImpressumBody_Mobile');
            setImpressumField('ImpressumField_Mobile');
            setImpressum_TextRow('Impressum_TextRow_Mobile');
            setImpressum_TextRow_Blank('Impressum_TextRow_Blank_Mobile');
            setImpressumField_Titel('ImpressumField_Titel_Mobile');
        }

    }, windowSize.current[0])

    return(
    <div className='ImpressumWrapper'>
    <Navigation></Navigation>
    <div className='SiteBackground'></div>
    <div className={ImpressumBody}>
        <div className={ImpressumField}>
            <div className={ImpressumField_Titel}>Impressum:</div>
            <div className={Impressum_TextRow}>Gerrit Alexander Helm</div>
            <div className={Impressum_TextRow}>Stauffenbergstraße 43</div>
            <div className={Impressum_TextRow}>07747 Jena</div>
            <div className={Impressum_TextRow_Blank}></div>
            <div className={Impressum_TextRow}>Mail: helm(at)aide-l(Punkt)de</div>
        </div>
    </div>
    <Footer></Footer>

</div>
    )   
}

export default Impressum